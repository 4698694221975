'use client'

import {
  ColumnDef,
  ColumnDefTemplate,
  ColumnOrderState,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  HeaderContext,
  Row,
  useReactTable,
  VisibilityState as VisibilityStateProps
} from '@tanstack/react-table'
import React, { useEffect, useState } from 'react'
import { IParamsTableInfinity } from '~/core/@types/global'
import Empty from '~/core/ui/Empty'
import { Pagination } from '~/core/ui/Pagination'
import { cn } from '~/core/ui/utils'

type FCC<Props = Record<string, unknown>> = React.FC<
  React.PropsWithChildren<Props>
>

const getStyleRow = ({
  thead,
  index,
  checked = false,
  config = { sticky: [], scrollXLeft: false, scrollXRight: false }
}: {
  thead?: boolean
  index: number
  checked: boolean
  config: {
    sticky?: Array<{
      index?: number
      position?: string
      value?: number
      useShadow?: boolean
    }>
    scrollXLeft: boolean
    scrollXRight: boolean
  }
}) => {
  const filter = (config.sticky || []).filter(
    (session) => session.index == index
  )
  const getFilter = filter && filter[0]
  const getClassShadow = `${
    getFilter?.useShadow
      ? getFilter.position === 'left'
        ? config.scrollXLeft
          ? `before:content-[''] before:absolute before:top-0 before:right-0 before:-bottom-[1px] before:pointer-events-none before:translate-x-full before:w-[30px] before:shadow-[10px_0_8px_-6px_inset_rgba(5,5,5,0.07)]`
          : ''
        : config.scrollXRight
        ? `before:content-[''] before:absolute before:top-0 before:left-0 before:-bottom-[1px] before:pointer-events-none before:-translate-x-full before:w-[30px] before:shadow-[-10px_0_8px_-6px_inset_rgba(5,5,5,0.07)]`
        : ''
      : ''
  }`

  const getRowWithShadow = `min-h-[40px] px-3 first:px-0 ${
    thead ? 'py-[9px]' : 'py-2'
  } ${getFilter && checked === false ? 'bg-white' : ''} ${
    getFilter && checked === true ? 'bg-gray-50' : ''
  } ${!getFilter ? 'bg-transparent' : ''} ${getClassShadow}`

  const customStyle = {
    position: getFilter ? 'sticky' : 'inherit',
    zIndex: getFilter ? '1' : '',
    left:
      getFilter && getFilter.position === 'left'
        ? index === 0
          ? 0
          : getFilter.value
        : 'inherit',
    right:
      getFilter && getFilter.position === 'right'
        ? index === 0
          ? 0
          : getFilter.value
        : 'inherit'
  }

  return {
    getRowWithShadow,
    customStyle
  }
}

const getTheadClass = (isHeaderSticky: boolean) => {
  return `bg-white dark:bg-gray-900 ${
    isHeaderSticky ? 'sticky top-0 left-0 right-0 z-10' : ''
  }`
}

const getWrapperClass = (classNameTable: string) => {
  return `overflow-x-auto overflow-y-auto ${classNameTable}`
}

interface IPagePaginationFetcher {
  fetchPagination?: (paginationParam: {
    pageSize: number
    currentPage: number
  }) => void
  forceChangeCurrentPage: number
}

interface dataQueryTablePaginationProps {
  data?: IPagePagination
  isFetching?: boolean
  fetcher?: IPagePaginationFetcher
}

interface TablePaginationProps {
  textOverride?: {
    [key: string]: string
  }
  search?: {
    globalFilter?: string | number
    setGlobalFilter?: (value: string) => void
    filter?: Object
  }
  emptyConfig?: {
    classNameEmpty?: string
    title?: string
    description?: string
    buttonTitle?: string
    buttonTitleOnClick?: () => void
    titleSearch?: string
    descriptionSearch?: string
    buttonTitleSearch?: string
    buttonTitleSearchOnClick?: () => void
  }
  tableConfig: {
    defaultPageSize: number
    showRowsPerPage?: boolean
  }
  dataQuery?: dataQueryTablePaginationProps
  columns?: ColumnDef<any, undefined>[]
  stickyConfig?: Array<{
    index?: number
    position?: string
    value?: number
    useShadow?: boolean
  }>
  classNameTable?: string
  classNamePaginationWrapper?: string
  isHeaderSticky?: boolean
  classNameSearchInput?: string
  placeholderSearchInput?: string
  columnVisibility?: VisibilityStateProps | undefined
  getSubRows?: (originalRow: object, index: number) => undefined | object[]
  columnOrder?: ColumnOrderState
  tableRef?: (tableEditor: any) => void
}

interface IPagePagination {
  data?: Array<object>
  meta?: {
    totalRowCount?: number
    currentPage?: number
    pageSize?: number
  }
}

interface IDataTablePagination {
  pages: Array<IPagePagination>
  pageParams?: Array<IParamsTableInfinity>
}

interface IDataPagePaginationResponse {
  [key: string]: {
    collection: Array<any>
    metadata: {
      totalCount: number
      currentPage?: number
      extras?: {
        totalCustomFieldSetting?: number
      }
    }
  }
}

const TbodyTable: FCC<{
  rows?: Array<Row<object>>
  columns: ColumnDef<any, undefined>[]
  dataQuery?: dataQueryTablePaginationProps
  configMode: {
    paddingTop: number
    paddingBottom: number
    sticky?: Array<{
      index?: number
      position?: string
      value?: number
      useShadow?: boolean
    }>
    scrollXLeft: boolean
    scrollXRight: boolean
    defaultPageSize?: number
  }
}> = ({ rows = [], dataQuery = {}, configMode, columns }) => {
  const rendered = (row: Row<object>) => {
    return (
      <tr
        key={row.id}
        className={cn(
          'border-b-solid table-tbody-hover border-b-[1px] border-b-gray-100',
          !row.getIsSelected()
            ? 'bg-white dark:bg-gray-900'
            : 'table-tbody-selected'
        )}>
        {row.getVisibleCells().map((cell, index) => {
          const { customStyle, getRowWithShadow } = getStyleRow({
            thead: false,
            index,
            checked: row.getIsSelected(),
            config: {
              sticky: configMode.sticky,
              scrollXLeft: configMode.scrollXLeft,
              scrollXRight: configMode.scrollXRight
            }
          })

          // @ts-expect-error
          if (cell?.getContext()?.row?.original?.isFEDeleted) return null

          return (
            <td
              key={cell.id}
              className={getRowWithShadow}
              style={{
                width: cell.column.getSize(),
                ...JSON.parse(JSON.stringify(customStyle))
              }}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          )
        })}
      </tr>
    )
  }

  return (
    <tbody>
      {!dataQuery.isFetching ? (
        <>{rows.map((row: Row<object>) => rendered(row))}</>
      ) : (
        <>
          {[...Array(configMode.defaultPageSize || 25).keys()].map((index) => {
            return (
              <tr key={index}>
                {columns.map((item, i) => {
                  const { customStyle, getRowWithShadow } = getStyleRow({
                    thead: false,
                    index: i,
                    checked: false,
                    config: {
                      sticky: configMode.sticky,
                      scrollXLeft: configMode.scrollXLeft,
                      scrollXRight: configMode.scrollXRight
                    }
                  })
                  return (
                    <td
                      key={i}
                      className={getRowWithShadow}
                      style={JSON.parse(JSON.stringify(customStyle))}>
                      <div className="h-4 w-full rounded-[2px] bg-gray-100" />
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </>
      )}
    </tbody>
  )
}

const TheadTable: FCC<{
  table: {
    getHeaderGroups: () => Array<{
      id: string
      headers: Array<{
        id: string
        colSpan: number
        getSize: Function
        getContext: Function
        isPlaceholder?: boolean
        column: {
          columnDef: {
            header?:
              | ColumnDefTemplate<HeaderContext<object, unknown>>
              | undefined
          }
        }
      }>
    }>
  }
  isHeaderSticky: boolean
  sticky?: Array<{
    index?: number
    position?: string
    value?: number
    useShadow?: boolean
  }>
  scrollXLeft: boolean
  scrollXRight: boolean
}> = ({ table, isHeaderSticky, sticky, scrollXLeft, scrollXRight }) => {
  return (
    <thead className={getTheadClass(isHeaderSticky)}>
      {table.getHeaderGroups().map((headerGroup) => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map((header, index) => {
            const { customStyle, getRowWithShadow } = getStyleRow({
              thead: true,
              index,
              checked: false,
              config: {
                sticky,
                scrollXLeft,
                scrollXRight
              }
            })

            return (
              <th
                key={header.id}
                colSpan={header.colSpan}
                style={{
                  width: header.getSize(),
                  ...JSON.parse(JSON.stringify(customStyle))
                }}
                className={`border-b-solid border-b-[1px] border-b-gray-100 ${getRowWithShadow}`}>
                {header.isPlaceholder ? null : (
                  <div className="text-left text-xs font-normal text-gray-600 dark:text-gray-300">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                )}
              </th>
            )
          })}
        </tr>
      ))}
    </thead>
  )
}

const TablePagination = ({
  textOverride,
  search = {},
  emptyConfig,
  tableConfig = {
    defaultPageSize: 25,
    showRowsPerPage: false
  },
  dataQuery = {},
  columns = [],
  stickyConfig = [],
  classNameTable = '',
  classNamePaginationWrapper = '',
  isHeaderSticky = false,
  columnVisibility,
  getSubRows,
  columnOrder = [],
  tableRef
}: TablePaginationProps) => {
  const tableContainerRef = React.useRef<HTMLDivElement>(null)
  const defaultData = React.useMemo(() => [], [])
  const sticky = React.useMemo(() => stickyConfig, [stickyConfig])
  const [columnVisibilityState, setColumnVisibility] = useState(
    columnVisibility || {}
  )
  const [expanded, setExpanded] = React.useState<ExpandedState>({})
  const positionRef = React.useRef(0)
  const [scrollXLeft, setScrollXLeft] = useState(false)
  const [scrollXRight, setScrollXRight] = useState(false)
  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    const x = e.currentTarget.scrollLeft
    if (x !== positionRef.current) {
      positionRef.current = x
      setScrollXLeft(x !== 0)
      setScrollXRight(x !== 0)
    }
  }

  useEffect(() => {
    if (columnVisibility) {
      setColumnVisibility(columnVisibility)
    }
  }, [columnVisibility])

  const table = useReactTable({
    data: dataQuery.data?.data || defaultData,
    columns: columns as ColumnDef<object, any>[],
    state: {
      columnVisibility: columnVisibilityState,
      expanded,
      columnOrder
    },
    onExpandedChange: setExpanded,
    getSubRows: getSubRows,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel()
  })

  const { rows } = table.getRowModel()

  const mergedEmptyConfig = {
    ...(emptyConfig || {}),
    show: dataQuery.isFetching === false && rows.length === 0
  }
  const isSearchEmpty = mergedEmptyConfig.show && !!search.globalFilter
  const defaultPageSize = tableConfig.defaultPageSize

  useEffect(() => {
    if (table && tableRef) {
      tableRef(table)
    }
  }, [table, tableRef])

  return (
    <>
      {mergedEmptyConfig.show === false ? (
        <>
          <div
            ref={tableContainerRef}
            className={getWrapperClass(classNameTable)}
            onScroll={handleScroll}>
            <table className="w-full table-fixed border-collapse border-spacing-0">
              <TheadTable
                table={table}
                isHeaderSticky={isHeaderSticky}
                sticky={sticky}
                scrollXLeft={scrollXLeft}
                scrollXRight={scrollXRight}
              />

              <TbodyTable
                rows={rows}
                columns={columns}
                dataQuery={dataQuery}
                configMode={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  sticky,
                  scrollXLeft,
                  scrollXRight,
                  defaultPageSize
                }}
              />
            </table>
          </div>

          {isSearchEmpty === false ? (
            <Pagination
              textOverride={textOverride}
              classNamePaginationWrapper={classNamePaginationWrapper}
              dataQuery={dataQuery}
              tableConfig={tableConfig}
            />
          ) : null}
        </>
      ) : (
        <div className={mergedEmptyConfig.classNameEmpty}>
          <div className="text-center">
            <Empty
              onClick={
                isSearchEmpty
                  ? mergedEmptyConfig.buttonTitleSearchOnClick
                  : mergedEmptyConfig.buttonTitleOnClick
              }
              type={isSearchEmpty ? 'empty-search' : 'empty-data'}
              title={
                isSearchEmpty
                  ? mergedEmptyConfig.titleSearch
                  : mergedEmptyConfig.title
              }
              description={
                isSearchEmpty
                  ? mergedEmptyConfig.descriptionSearch
                  : mergedEmptyConfig.description
              }
              buttonTitle={
                isSearchEmpty
                  ? mergedEmptyConfig.buttonTitleSearch
                  : mergedEmptyConfig.buttonTitle
              }
            />
          </div>
        </div>
      )}
    </>
  )
}

export { TablePagination }
export type {
  dataQueryTablePaginationProps,
  TablePaginationProps,
  IPagePagination,
  IDataTablePagination,
  IDataPagePaginationResponse,
  IPagePaginationFetcher
}

