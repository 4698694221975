'use client'

import { useEffect, useState } from 'react'
import ComboboxSelect from '~/core/ui/ComboboxSelect'
import { Divider } from '~/core/ui/Divider'
import { IconButton } from '~/core/ui/IconButton'
import { NativeSelect } from '~/core/ui/NativeSelect'
import { ISelectOption } from '~/core/ui/Select'
import { dataQueryTablePaginationProps } from '~/core/ui/TablePagination'
import { TypographyText } from '~/core/ui/Text'
import { cn } from '~/core/ui/utils'
import { PAGINATION_SIZE } from '../constants/enum'

interface PaginationProps {
  textOverride?: {
    [key: string]: string
  }
  classNamePaginationWrapper?: string
  dataQuery: dataQueryTablePaginationProps
  tableConfig: {
    defaultPageSize: number
    showRowsPerPage?: boolean
  }
}

const Pagination = (props: PaginationProps) => {
  const { textOverride, classNamePaginationWrapper, dataQuery, tableConfig } =
    props
  const currentPage = dataQuery.data?.meta?.currentPage || 1
  const defaultPageSize = tableConfig.defaultPageSize
  const [pagination, setPagination] = useState({
    pageSize: defaultPageSize,
    currentPage
  })
  const showRowsPerPage = tableConfig.showRowsPerPage || false
  const totalRowCount = dataQuery.data?.meta?.totalRowCount || 1
  const calTotalPageOf = Math.ceil(
    totalRowCount > pagination.pageSize
      ? totalRowCount / pagination.pageSize
      : 1
  )
  const calTotalPage = `${textOverride?.of || 'of'} ${calTotalPageOf}`

  useEffect(() => {
    if (dataQuery.fetcher?.forceChangeCurrentPage && dataQuery.fetcher?.forceChangeCurrentPage > 0) {
      setPagination({
        ...pagination,
        currentPage: 1
      })
    }
  }, [dataQuery.fetcher?.forceChangeCurrentPage])

  return (
    <div
      className={cn(
        'flex items-center justify-end py-3',
        classNamePaginationWrapper
      )}>
      <div className="flex h-6 items-center space-x-1.5">
        <IconButton
          size="xs"
          type="secondary"
          isDisabled={dataQuery.isFetching || pagination.currentPage === 1}
          onClick={() => {
            setPagination({
              ...pagination,
              currentPage: pagination.currentPage - 1
            })
            if (dataQuery.fetcher?.fetchPagination) {
              dataQuery.fetcher.fetchPagination({
                ...pagination,
                currentPage: pagination.currentPage - 1
              })
            }
          }}
          iconMenus="ChevronLeft"
        />

        <TypographyText className="text-xs leading-6 text-gray-600">
          {textOverride?.page || 'Page'}
        </TypographyText>

        {calTotalPageOf <= 20 ? (
          <NativeSelect
            menuPlacement="top"
            menuPosition="fixed"
            isSearchable={false}
            isClearable={false}
            isDisabled={dataQuery.isFetching}
            size="xs"
            classNameOverride={{
              valueContainer: 'pl-0',
              control: '!border-transparent !shadow-none',
              menuPortal: '!z-[12345678901]',
              menu: '!min-w-[100px] right-0',
              menuList: '!max-h-[200px]',
              indicatorsContainer: '!pr-0 !pl-1'
            }}
            classNameOption={{
              value: 'text-xs'
            }}
            value={{
              value: String(pagination.currentPage),
              supportingObj: {
                name: String(pagination.currentPage)
              }
            }}
            onChange={(newValue) => {
              setPagination({
                ...pagination,
                currentPage: Number((newValue as ISelectOption).value)
              })
              if (dataQuery.fetcher?.fetchPagination) {
                dataQuery.fetcher.fetchPagination({
                  ...pagination,
                  currentPage: Number((newValue as ISelectOption).value)
                })
              }
            }}
            options={Array.from(Array(calTotalPageOf + 1).keys())
              .slice(1)
              .map((item) => ({
                value: String(item),
                supportingObj: {
                  name: String(item)
                }
              }))}
          />
        ) : (
          <ComboboxSelect
            closeOnSelect
            isSearchable
            isClearable={false}
            isDisabled={dataQuery.isFetching}
            options={Array.from(Array(calTotalPageOf + 1).keys())
              .slice(1)
              .map((item) => ({
                value: String(item),
                supportingObj: {
                  name: String(item)
                }
              }))}
            value={{
              value: String(pagination.currentPage),
              supportingObj: {
                name: String(pagination.currentPage)
              }
            }}
            onChange={(newValue) => {
              setPagination({
                ...pagination,
                currentPage: Number((newValue as ISelectOption).value)
              })
              if (dataQuery.fetcher?.fetchPagination) {
                dataQuery.fetcher.fetchPagination({
                  ...pagination,
                  currentPage: Number((newValue as ISelectOption).value)
                })
              }
            }}
            size="sm"
            placeholder={textOverride?.placeholder || 'Search'}
            searchPlaceholder={textOverride?.search || 'Search'}
            loadingMessage={textOverride?.loading || 'Loading'}
            noOptionsMessage={textOverride?.noOptions || 'No options'}
            buttonClassName="w-full !border-transparent !shadow-none !px-0 !ring-0 !ring-offset-0 bg-transparent"
            dropdownMenuPortalClassName="!z-[12345678901]"
            dropdownMenuClassName="!min-w-[100px]"
            containerMenuListClassName="!max-h-[200px]"
            menuOptionAlign="end"
            menuOptionSide="top"
          />
        )}

        <TypographyText className="text-xs leading-6 text-gray-600">
          {calTotalPage}
        </TypographyText>

        <IconButton
          size="xs"
          type="secondary"
          isDisabled={
            dataQuery.isFetching ||
            pagination.currentPage * pagination.pageSize >=
              Number(totalRowCount)
          }
          onClick={() => {
            setPagination({
              ...pagination,
              currentPage: pagination.currentPage + 1
            })
            if (dataQuery.fetcher?.fetchPagination) {
              dataQuery.fetcher.fetchPagination({
                ...pagination,
                currentPage: pagination.currentPage + 1
              })
            }
          }}
          iconMenus="ChevronRight"
        />
      </div>

      {showRowsPerPage === true ? (
        <div className="flex h-6 items-center space-x-2">
          <Divider className="mx-2 h-4 w-px bg-gray-100" />

          <TypographyText className="py-[3px] text-xs leading-6 text-gray-600">
            {textOverride?.rowsPerPage || 'Rows per page'}
          </TypographyText>

          <NativeSelect
            isDisabled={dataQuery.isFetching}
            menuPlacement="top"
            menuPosition="fixed"
            isSearchable={false}
            isClearable={false}
            size="xs"
            classNameOverride={{
              valueContainer: 'pl-0',
              control: '!border-transparent !shadow-none',
              menuPortal: '!z-[12345678901]',
              menu: '!min-w-[88px] right-0',
              menuList: '!max-h-[200px]',
              indicatorsContainer: '!pr-0 !pl-1'
            }}
            classNameOption={{
              value: 'text-xs'
            }}
            value={{
              value: String(pagination.pageSize),
              supportingObj: {
                name: String(pagination.pageSize)
              }
            }}
            onChange={(newValue) => {
              setPagination({
                currentPage: 1,
                pageSize: Number((newValue as ISelectOption).value)
              })
              if (dataQuery.fetcher?.fetchPagination) {
                dataQuery.fetcher.fetchPagination({
                  currentPage: 1,
                  pageSize: Number((newValue as ISelectOption).value)
                })
              }
            }}
            options={PAGINATION_SIZE.map((item) => ({
              value: String(item),
              supportingObj: {
                name: String(item)
              }
            }))}
          />
        </div>
      ) : null}
    </div>
  )
}

Pagination.displayName = 'Pagination'

export { Pagination }
export type { PaginationProps }
