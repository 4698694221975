import { useQuery } from '@tanstack/react-query'
import { useCallback, useEffect, useRef, useState } from 'react'
import { DocumentInput } from 'urql'
import configuration from '~/configuration'
import useContextGraphQL, {
  IResponseContextResult
} from '~/core/middleware/use-context-graphQL'
import { catchErrorFromGraphQL } from '~/core/utilities/catch-api-error'

export interface IPagePaginationResult<PageItem extends { id?: string }> {
  data: PageItem[]
  meta: { totalRowCount: number; extras?: any }
}

const DEFAULT_PAGE = 1

const usePaginationGraphPage = <
  Data extends { id?: string },
  Variables extends {
    page: number
    limit: number
    search?: string
    [key: string]: unknown
  }
>({
  pageSize,
  queryDocumentNode,
  filter,
  enabled = true,
  queryKey = 'table-data'
}: {
  pageSize?: number
  queryDocumentNode: DocumentInput<
    {
      [key: string]: { collection: Data[]; metadata: { totalCount: number } }
    },
    Variables
  >
  filter: Omit<Variables, 'page' | 'limit'>
  enabled?: boolean
  queryKey?: string
}) => {
  const { clientGraphQL } = useContextGraphQL()
  const [globalFilter, setGlobalFilter] = useState('')
  const [forceChangeCurrentPage, setForceChangeCurrentPage] = useState(0)
  const [filterChange, setFilterChange] = useState()
  const [filterWithPagination, setFilterWithPagination] =
    useState<Omit<Variables, 'page' | 'limit'>>(filter)

  function getObjectDiff(
    original: Omit<Variables, 'page' | 'limit'>,
    current: Omit<Variables, 'page' | 'limit'>
  ) {
    if (original) {
      delete original.page
      delete original.limit
    }

    if (current) {
      delete current.page
      delete current.limit
    }

    return JSON.stringify(original) === JSON.stringify(current)
  }

  const fetchData = useCallback(
    (pageParam: Variables): Promise<IPagePaginationResult<Data>> => {
      return clientGraphQL
        .query(queryDocumentNode, {
          ...pageParam,
          search: pageParam?.search ? pageParam.search : globalFilter,
          page: pageParam.page || DEFAULT_PAGE,
          limit: pageParam.limit || pageSize || configuration.defaultPageSize,
          key: undefined,
          isFilterTouched: undefined
        })
        .toPromise()
        .then((result: IResponseContextResult<{}>) => {
          setFilterChange({
            ...pageParam,
            limit: undefined,
            page: undefined
          })

          if (result.error) {
            return catchErrorFromGraphQL({
              error: result.error
            })
          }

          const keys = Object.keys(result.data)
          if (keys.length !== 1) {
            throw new Error('Invalid query result')
          }
          const data = result.data[keys[0]]

          if (data.metadata?.totalCount === undefined) {
            return {
              data,
              meta: {
                totalRowCount: data.metadata?.totalCount,
                currentPage: pageParam.page,
                extras: data.metadata?.extras
              }
            }
          }

          return {
            data: data.collection,
            meta: {
              totalRowCount: data.metadata?.totalCount,
              currentPage: pageParam.page,
              extras: data.metadata?.extras
            }
          }
        })
    },
    [clientGraphQL, queryDocumentNode]
  )

  const { status, data, error, isFetching, refetch } = useQuery(
    [queryKey, filterWithPagination],
    async (pageParams): Promise<IPagePaginationResult<Data>> => {
      const getQueryKey = pageParams?.queryKey?.[1] as Variables
      return fetchData(getQueryKey)
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled
    }
  )

  const timeoutRef = useRef<NodeJS.Timeout>()
  useEffect(() => {
    if (!!timeoutRef?.current) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      if (filterChange) {
        const compareObject = (
          filterChange ? getObjectDiff(filterChange, filter) : {}
        ) as {
          [type: string]: unknown
        }

        console.log({ compareObject })
        if (!compareObject) {
          setFilterWithPagination({
            ...filterWithPagination,
            ...filter,
            page: DEFAULT_PAGE
          })
          setForceChangeCurrentPage((previousData) => previousData + 1)
        }
      }
    }, 150)

    return () => clearTimeout(timeoutRef.current)
  }, [filter])

  const fetchPagination = useCallback(
    (paginationParam?: { pageSize: number; currentPage: number }) => {
      setFilterWithPagination({
        ...filterWithPagination,
        limit: paginationParam
          ? paginationParam.pageSize
          : filterWithPagination.pageSize,
        page: paginationParam
          ? paginationParam.currentPage
          : filterWithPagination.currentPage
      })
    },
    [filterWithPagination]
  )

  const goToPage = useCallback(
    (page: number) => {
      setFilterWithPagination({
        ...filterWithPagination,
        page
      })
    },
    [filterWithPagination]
  )

  return {
    data,
    page: filterWithPagination?.page || DEFAULT_PAGE,
    status,
    error,
    isFetching,
    refetch,
    fetchPagination,
    forceChangeCurrentPage,
    goToPage,
    filter,
    globalFilter,
    setGlobalFilter
  }
}

export default usePaginationGraphPage
