import { useCallback, useMemo, useState } from 'react'
import configuration from '~/configuration'
import usePaginationGraphPage from '~/lib/hooks/use-pagination-graph-page'
import { useSubmitCommon } from '~/lib/hooks/use-submit-graphql-common'
import { FILTER_BY_DEFAULT } from '../../agency/companies/utilities/company-detail-enum'
import DeleteTaskMutation from '../../tasks/graphql/delete-task-mutation'
import UpdateTaskMutation from '../../tasks/graphql/update-task-mutation'
import { ITasksManagementFilter } from '../../tasks/types'
import { TAB_KEYS } from '../../tasks/utilities/enum'
import QueryTasksList from '../graphql/query-tasks-list'

export const useMyTasksManagement = () => {
  const [filterValue, onChangeFilter] = useState<ITasksManagementFilter>({
    tab: TAB_KEYS[0].value
  })

  const {
    data,
    page,
    error,
    refetch,
    fetchPagination,
    forceChangeCurrentPage,
    isFetching,
    globalFilter,
    setGlobalFilter
  } = usePaginationGraphPage({
    pageSize: configuration.defaultTableMountPageSize,
    queryDocumentNode: QueryTasksList,
    queryKey: 'my-dashboard-task',
    filter: {
      ...filterValue,
      tab: String(filterValue?.tab),
      filterBy: FILTER_BY_DEFAULT
    }
  })

  const { trigger: deleteTask, isLoading: deletingTask } =
    useSubmitCommon(DeleteTaskMutation)
  const { trigger: updateTask, isLoading: updatingTask } =
    useSubmitCommon(UpdateTaskMutation)

  const refetchAndCheckLastItem = useCallback(
    (previousTypeAction?: 'delete' | 'edit') => {
      if (
        previousTypeAction === 'delete' &&
        page > 1 &&
        (data?.meta.totalRowCount || 0) % 4 === 1
      ) {
        //in case user delete the last item in page > 1, it will load previous page
        fetchPagination()
      } else refetch()
    },
    [page, data]
  )

  return {
    data,
    refetch: refetchAndCheckLastItem,
    page,
    filterControl: useMemo(
      () => ({ value: filterValue, onChange: onChangeFilter }),
      [filterValue]
    ),
    action: {
      taskDeleteAction: {
        deleteTask: (args: { id: number; profileId?: number }) =>
          deleteTask(args),
        deletingTask
      },
      updateTaskAction: {
        updateTask: (args: {
          id: number
          title?: string
          profileId?: number | null
          companyId?: number | null
          dueDate?: string
          assigneeIds?: Array<number>
        }) => updateTask(args),
        updatingTask,
        updateTaskStatus: (args: {
          id: number
          status?: string
          title?: string
          assigneeIds?: Array<number>
        }) => updateTask(args)
      }
    },
    error,
    isFetching,
    globalFilter,
    setGlobalFilter,
    fetchPagination,
    forceChangeCurrentPage,
    defaultPageSize: configuration.defaultTableMountPageSize
  }
}
