import { formatISO, lastDayOfMonth, startOfMonth } from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import configuration from '~/configuration'
import { IUserInformation } from '~/core/@types/global'
import useContextGraphQL from '~/core/middleware/use-context-graphQL'
import usePaginationGraphPage from '~/lib/hooks/use-pagination-graph-page'
import QueryUpdateInterviewMutation from '../../calendar/graphql/mutation-update-interview'
import {
  IInterviewsManagementFilter,
  InterviewParamsType
} from '../../calendar/types'
import { addTzToDate } from '../../calendar/utilities/helper-schedule-interview'
import QueryInterviewsCandidateList from '../graphql/query-interviews-candidate-list'

export const Key_Tabs = {
  feedback_due: 'feedback_due',
  interview_upcoming: 'interview_upcoming'
}

const useMyInterviewsManagement = ({ user }: { user: IUserInformation }) => {
  const { clientGraphQL } = useContextGraphQL()
  const [filterValue, onChangeFilter] = useState<IInterviewsManagementFilter>({
    filterBy: Key_Tabs.interview_upcoming
  })

  const {
    data,
    page,
    error,
    refetch,
    fetchPagination,
    forceChangeCurrentPage,
    isFetching
  } = usePaginationGraphPage({
    pageSize: configuration.defaultTableMountPageSize,
    queryDocumentNode: QueryInterviewsCandidateList,
    queryKey: 'my-dashboard-interview',
    filter: {
      filterBy: filterValue?.filterBy || Key_Tabs.interview_upcoming
    }
  })

  useEffect(() => {
    user?.timezone &&
      onChangeFilter({
        fromDatetime: addTzToDate(
          formatISO(startOfMonth(new Date())),
          user?.timezone
        ),
        toDatetime: addTzToDate(
          formatISO(lastDayOfMonth(new Date())),
          user?.timezone
        ),
        attendeeIds: [
          {
            value: String(user?.id),
            supportingObj: {
              name: user?.fullName || '',
              defaultColour: user?.defaultColour
            },
            avatar: user.avatarVariants?.thumb?.url,
            avatarVariants: user?.avatarVariants
          }
        ]
      })
  }, [
    user?.avatarVariants,
    user?.defaultColour,
    user?.fullName,
    user?.id,
    user?.timezone
  ])

  const updateInterview = useCallback<
    (data: InterviewParamsType) => Promise<void>
  >(
    (data) => {
      return clientGraphQL
        .mutation(QueryUpdateInterviewMutation, data)
        .toPromise()
        .then(
          (rs: {
            data: {
              interview: {
                id: string
                fromDatetime: string
                toDatetime: string
                ikitFeedbacksSummary: Array<{}>
              }
            }
          }) => {
            return rs.data
          }
        )
    },
    [clientGraphQL]
  )

  const refetchAndCheckLastItem = useCallback(
    (previousTypeAction?: 'delete' | 'edit') => {
      if (
        previousTypeAction === 'delete' &&
        page > 1 &&
        (data?.meta.totalRowCount || 0) % 4 === 1
      ) {
        //in case user delete the last item in page > 1, it will load previous page
        fetchPagination()
      } else refetch()
    },
    [page, data]
  )

  return {
    interviewsListControl: {
      data,
      defaultPageSize: configuration.defaultTableMountPageSize,
      isFetching,
      error,
      page,
      refetch: refetchAndCheckLastItem,
      fetchPagination,
      forceChangeCurrentPage
    },
    filterControl: useMemo(
      () => ({ value: filterValue, onChange: onChangeFilter }),
      [filterValue]
    ),
    action: {
      interviewUpdateAction: {
        updateInterview: (args: InterviewParamsType) => updateInterview(args)
      }
    }
  }
}

export default useMyInterviewsManagement
