import { gql } from 'urql'
import { TaskItemType } from '../../tasks/types'

const QueryTasksList = gql<
  {
    tasksList: {
      collection: Array<TaskItemType>
      metadata: {
        totalCount: number
      }
    }
  },
  {
    page: number
    limit: number
    tab: string
    profileId?: number
    applicantId?: number
    filterBy: string
  }
>`
  query (
    $page: Int
    $limit: Int
    $tab: String
    $profileId: Int
    $applicantId: Int
    $filterBy: String
  ) {
    tasksList(
      page: $page
      limit: $limit
      tab: $tab
      profileId: $profileId
      applicantId: $applicantId
      filterBy: $filterBy
    ) {
      collection {
        id
        title
        dueDate
        status
        profile {
          id

          fullName
          avatarVariants
        }
        assignees {
          id
          fullName
          avatarVariants
          defaultColour

          roles {
            id
            name
          }
        }
        comments {
          content
        }
        company {
          id
          name
        }
        applicant {
          profile {
            id
            fullName
          }
        }

        applicantId
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryTasksList
