import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useCallback, useEffect } from 'react'
import configuration from '~/configuration'
import Empty from '~/core/ui/Empty'
import { Pagination } from '~/core/ui/Pagination'
import { Tabs, TabsList, TabsTrigger, TabsTriggerView } from '~/core/ui/Tabs'
import { TextButton } from '~/core/ui/TextButton'
import useScheduleInterviewCalendarHook from '~/lib/features/calendar/hooks/use-schedule-interview-calendar'
import useMyInterviewsManagement, {
  Key_Tabs
} from '~/lib/features/dashboard/hooks/use-my-interview'
import useBoundStore from '~/lib/store'
import ScheduleInterviewModal from '../Calendar/ScheduleInterview/ScheduleInterviewModal'
import MyInterviewItemView from './Components/MyInterviewItemView'
const TAB_KEYS = [
  { value: 'interviews', key: 'interview_upcoming' },
  { value: 'feedback', key: 'feedback_due' }
]

function MyInterviewView() {
  const { user, refetchMyList, setRefetchMyList } = useBoundStore()
  const { t } = useTranslation()
  const { interviewsListControl, action, filterControl } =
    useMyInterviewsManagement({ user })
  const {
    editModal,
    setEditModal,
    onCloseEditModal,
    onReopenEditModal,
    onScheduleInterview,
    interview
  } = useScheduleInterviewCalendarHook({
    updateInterview: action.interviewUpdateAction.updateInterview
  })

  const onChangeTab = useCallback((value: string) => {
    filterControl.onChange({
      filterBy:
        value === TAB_KEYS[0].value
          ? Key_Tabs.interview_upcoming
          : Key_Tabs.feedback_due
    })
  }, [])

  useEffect(() => {
    if (refetchMyList) {
      interviewsListControl.refetch('delete')
      setRefetchMyList(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchMyList])

  const renderedTabs = () => {
    return (
      <div className="mr-4 flex items-start justify-between border-b border-b-gray-100">
        <Tabs
          value={
            filterControl?.value?.filterBy === Key_Tabs.feedback_due
              ? TAB_KEYS[1].value
              : TAB_KEYS[0].value
          }
          onValueChange={onChangeTab}>
          <TabsList size="sm">
            {TAB_KEYS.map((tab, index) => (
              <TabsTrigger
                classNameButton="pb-2.5"
                key={`tab-${index}`}
                size="sm"
                value={tab.value}>
                <TabsTriggerView
                  size="sm"
                  session={{
                    value: tab.value,
                    label:
                      index === 0
                        ? (t('dashboard:myInterview:tabs:upcoming') as string)
                        : (t(
                            'dashboard:myInterview:tabs:feedbackDue'
                          ) as string)
                  }}
                />
              </TabsTrigger>
            ))}
          </TabsList>
        </Tabs>

        <Link href={configuration.path.calendar.list}>
          <TextButton underline={false}>{t(`button:viewAll`)}</TextButton>
        </Link>
      </div>
    )
  }

  const renderedEmpty = () => (
    <>
      {totalRowCount === 0 ? (
        <div className="mr-4 flex h-[calc(100%_-_50px)] items-center justify-center">
          <div className="px-0.5 pt-0.5">
            <Empty
              type="empty-data"
              title={
                filterControl.value.filterBy === Key_Tabs.feedback_due
                  ? `${t('dashboard:myInterview:empty:titleFeedback')}`
                  : `${t('dashboard:myInterview:empty:titleUpcoming')}`
              }
              description={undefined}
              buttonTitle={undefined}
              onClick={() => {}}
            />
          </div>
        </div>
      ) : null}
    </>
  )

  const defaultPageSize = interviewsListControl.defaultPageSize
  const totalRowCount = interviewsListControl?.data?.meta?.totalRowCount || 0
  return (
    <div className="relative h-full">
      {renderedTabs()}
      {!interviewsListControl?.isFetching &&
      (interviewsListControl?.data?.data || []).length === 0
        ? renderedEmpty()
        : null}
      {(interviewsListControl?.data?.data || []).length > 0 ? (
        <>
          <div className="mt-4 h-[calc(100%_-_86px)] space-y-3 overflow-y-scroll">
            {interviewsListControl?.data?.data.map((interview, index) => (
              <MyInterviewItemView
                interviewsListControl={interviewsListControl}
                setEditModal={setEditModal}
                interview={interview}
                action={action}
                key={index}
              />
            ))}
          </div>

          <Pagination
            classNamePaginationWrapper="py-1 mr-4"
            textOverride={{
              of: `${t('label:of')}`,
              page: `${t('label:page')}`,
              placeholder: `${t('label:placeholder:select')}`,
              search: `${t('label:placeholder:search')}`,
              loading: `${t('label:loading')}`,
              noOptions: `${t('label:noOptions')}`,
              rowsPerPage: `${t('label:rowsPerPage')}`
            }}
            dataQuery={{
              isFetching: interviewsListControl.isFetching,
              fetcher: {
                fetchPagination: interviewsListControl.fetchPagination,
                forceChangeCurrentPage:
                  interviewsListControl.forceChangeCurrentPage
              },
              data: interviewsListControl.data
            }}
            tableConfig={{
              defaultPageSize
            }}
          />
        </>
      ) : null}
      {!!interview.data && (
        <ScheduleInterviewModal
          open={editModal.open}
          onClose={editModal?.onCloseEditModal || onCloseEditModal}
          isEditInterview={true}
          reopenInterviewModal={
            editModal?.onReopenEditModal || onReopenEditModal
          }
          reload={interview.fetchData}
          interviewInfo={{
            ...interview.data,
            ...(editModal.newDataInterview || {}),
            jobStageId: interview.data?.jobStage?.id
              ? Number(interview.data?.jobStage?.id)
              : undefined
          }}
          onFinish={onScheduleInterview}
          candidateProfile={{
            id: Number(interview?.data?.profile?.id),
            email: interview?.data?.profile?.email
          }}
        />
      )}
    </div>
  )
}

export default MyInterviewView
